<template>
  <!--  Users list  -->
  <div class="users-list" id="loans-list">
    <div class="col-12 users-list-item pe-3" :class="{ activeLoan: loan.id === activeLoan.id }"
      v-for="loan in loansList" :key="loan.id" @click="$emit('select-loan', loan)">
      <div class="users-list-item__title">
        <div class="borrower-name">{{ loan.borrowerName }}</div>
        <div class="last-touch">{{ loan.lastTouchAt }}</div>
      </div>
      <div class="row mt-2 users-list-item__status">
        <div class="col-1 my-auto">
          <img src="@/assets/icons/icon-touched-18.svg" alt="touched" v-if="loan.isDailyTouched"/>
          <img src="@/assets/icons/icon-untouched-18.svg" alt="untouched" v-else/>
        </div>
        <div class="col-10 my-auto p-0 " :class="{ touched: loan.isDailyTouched }">
          {{ loan.isDailyTouched ? "Touched" : "Untouched" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: "TouchScreenLoansList",
  props: {
    loans: {
      type: Array,
      default: () => [],
    },
    activeLoan: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      ui: {
        ps: null
      },
      loansList: this.loans
    }
  },
  mounted() {
    this.$nextTick(() => {
      const scrollableBlock = document.getElementById('loans-list')
      this.ui.ps = new PerfectScrollbar(scrollableBlock, {
        useBothWheelAxes: false,
        suppressScrollX: true
      });
    })
  },
  updated() {
    this.$nextTick(() => {
      this.ui.ps.update()
    })
  },
  beforeDestroy() {
    this.$nextTick(() => {
      this.ui.ps.destroy()
    })
  },
  watch: {
    loans: function (newVal) {
      this.loansList = newVal
    },
  }
};
</script>

<style lang="scss" scoped>
.users-list {
  &-item {
    padding: 9px 0 12px 21px;
    border-bottom: 1px solid #CCCCCC;
    cursor: pointer;
    transition: all 0.3s;

    @media (max-width: 1024px) {
      padding: 10px 0 10px 10px;
    }

    &.selected {
      background: #f5f5f5;
    }

    &.activeLoan,
    &:hover {
      background-color: rgba(0,0,0, .05);
      .borrower-name {
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        //line-height: 18px;
      }
      .last-touch {
        opacity: 0.9;
        color: #000000;
        font-size: 11px;
        letter-spacing: 0;
        //line-height: 11px;
      }
      .users-list-item__status,
      .touched {
        opacity: 0.9;
        color: #000000;
        font-size: 11px;
        letter-spacing: 0;
        img {
          filter: brightness(0);
        }
      }
    }

    &:hover {
      .users-list-item__title {
        &:after {
          width: 100%;
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 18px;
      color: #000;
      letter-spacing: 0;
      font-weight: 500;
    }

    &__status {
      color: #000000;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      .touched {
        color: #28A745;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        margin-left: 6px!important;
      }
    }
  }
}

.last-touch {
  opacity: 0.46;
  color: #000000;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 11px;
}
.borrower-name {
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  transition: all .3s;
}
</style>
