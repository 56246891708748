<template>
    <div>
        <div class="status-msg__text text-center">
            {{ msg }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatusMessage',
    props: {
        msg: {
            type: String,
            default: 'This is an information message',
            required: true
        }
    }
}
</script>
