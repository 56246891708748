<template>
  <div class="task-list">
    <div class="task-list__body">
      <div class="task-list__item">
        <div class="d-flex align-items-center">
          <img src="@/assets/icons/icon-done.svg" alt="complete" v-if="loan.info.expDate">
          <img src="@/assets/icons/icon-not-complete.svg" v-else alt="not-complete">
          <span class="task-title">Lock Exp:</span>
          <input type="date" aria-label="Exp date" class="date-input"
                 :value="loan.info.expDate ? formatDate(loan.info.expDate, 'yyyy-MM-DD') : null"
                 @change="updateExpDate(loan, $event)" :min="currentDate">
        </div>
      </div>
      <div v-for="(stage, index) in loanStages" class="task-item mt-2" :key="stage.id">
        <div class="d-flex align-items-center" v-if="stage.id === 7">
          <img src="@/assets/icons/icon-done.svg" alt="complete" v-if="loan.info.appraisalDueDate">
          <img src="@/assets/icons/icon-not-complete.svg" v-else alt="not-complete">
          <span class="task-title ms-2">Appraisal Due Date: </span>
          <input type="date" aria-label="Appraisal Due Date" class="date-input"
                 :value="loan.info.appraisalDueDate ? formatDate(loan.info.AppraisalDueDate, 'yyyy-MM-DD') : null"
                 @change="updateAppDueDate(loan, $event)">
        </div>
        <div v-else-if="stage.id === 10">
          <span :name="'loan_stage' + loan.id + index" class="unw-status me-2" @click="openUWModal(loan, stage.id, loan.stages[stage.id] ? loan.stages[stage.id].status : null, stage.id)">
            {{ loan.stages[stage.id] ? loan.stages[stage.id].data['value'] : null }}
          </span> {{ stage.name | strippedContent }}
          <tippy v-if="loan.stages[stage.id]"
                 :to="'loan_stage' + loan.id + index"
                 arrow="true"
                 boundary="window"
                 interactive="true"
                 animateFill="false"
                 placement="right-center"
                 zIndex="99"
                 hideOnClick="true"
                 theme="light"
                 sticky="true"
                 class="g"
          >
            <div class="stage-tippy">
              <div v-for="(item, itemInd) in loan.stages[stage.id].data['uw_activity']" :key="itemInd">
                <span style="text-align: right">
                  <b>{{ item.status }}:</b> {{ formatDate(item.updated_at.datetime) }}
                </span>
                <br>
              </div>
            </div>
          </tippy>
        </div>
        <div class="d-flex align-items-center mt-2" v-else>
          <div v-if="loan.stages[stage.id] && loan.stages[stage.id].status === 'complete'">
            <img src="@/assets/icons/icon-done.svg" alt="complete" class="cursor-pointer" :name="'loan_stage' + loan.id + index"
                 @click="setStageStatus(loan, stage.id, 'not-complete', stage.id)">
            <tippy
              :to="'loan_stage' + loan.id + index"
              arrow="true"
              boundary="window"
              interactive="true"
              animateFill="false"
              placement="right-center"
              zIndex="99"
              hideOnClick="true"
              theme="light"
              sticky="true"
              class="g"
            >
              <div class="stage-tippy">
                <span v-if="loan.stages[stage.id].data['in-progress']">
                  <b>Requested:</b> {{ formatDate(loan.stages[stage.id].data['in-progress'].date) }}
                </span> <br>
                <span v-if="loan.stages[stage.id].data['complete']">
                  <b>Received:</b> {{ formatDate(loan.stages[stage.id].data['complete'].date) }}
                </span>
              </div>
            </tippy>
          </div>
          <div v-else-if="loan.stages[stage.id] && loan.stages[stage.id].status === 'in-progress'">
            <img src="@/assets/icons/icon-in-progress.svg" alt="in-progress" class="cursor-pointer" :name="'loan_stage' + loan.id + index"
                 @click="setStageStatus(loan, stage.id, 'complete', stage.id)">
            <tippy
              :to="'loan_stage' + loan.id + index"
              arrow="true"
              boundary="window"
              interactive="true"
              animateFill="false"
              placement="right-center"
              zIndex="99"
              hideOnClick="true"
              theme="light"
              sticky="true"
              class="g"
            >
              <div class="stage-tippy">
                <span v-if="loan.stages[stage.id].data['in-progress']">
                  <b>Requested:</b> {{ formatDate(loan.stages[stage.id].data['in-progress'].date) }}
                </span> <br>
                <span v-if="loan.stages[stage.id].data['complete']">
                  <b>Received:</b> {{ formatDate(loan.stages[stage.id].data['complete'].date) }}
                </span>
              </div>
            </tippy>
          </div>
          <img src="@/assets/icons/icon-not-complete.svg" v-else alt="not-complete" class="cursor-pointer"
               @click="setStageStatus(loan, stage.id, 'in-progress', stage.id)">
          <span class="task-title ms-2">
            {{ stage.name | strippedContent }}
          </span>
        </div>
      </div>
      <div class="task-list__item">
        <div class="d-flex align-items-center">
          <img src="@/assets/icons/icon-done.svg" alt="complete" v-if="loan.info.signingDate">
          <img src="@/assets/icons/icon-not-complete.svg" v-else alt="not-complete">
          <span class="task-title">Signing Date:</span>
          <input type="date" aria-label="Exp date" class="date-input"
                 :value="loan.info.signingDate ? formatDate(loan.info.signingDate, 'yyyy-MM-DD') : null"
                 @change="updateSigningDate(loan, $event)" :min="currentDate">
        </div>
      </div>
    </div>
    <transition name="component-fade" mode="out-in">
      <UpdateUWStatusModal v-if="modals.updateUWStatus" :status="UWStatusData.status"
                           @updateStatus="updateUWStatus"
                           @close="UWStatusData = {}; modals.updateUWStatus = false">
      </UpdateUWStatusModal>
    </transition>
  </div>
</template>

<script>
import UpdateUWStatusModal from "@/views/loans/UpdateUWStatusModal";
export default {
  name: 'TouchScreenTaskList',
  props: {
    loan: { type: Object, required: true },
    stages: { type: Array, required: true },
  },
  components: { UpdateUWStatusModal },
  data() {
    return {
      loanStages: Object.values(this.stages),
      currentDate: new Date().toISOString().split('T')[0],
      UWStatusData: {
        loan: null,
        stageId: null,
        status: null,
        index: null,
      },
      modals: {
        updateUWStatus: false
      },
    }
  },
  methods: {
    setStageStatus(loan, stageId, status, index, e) {
      let date = null;
      if (e) { date = e.target.value }

      let formData = new FormData();
      formData.append('stageId', stageId)
      formData.append('status', status)
      formData.append('date', date)

      this.$http
        .post(`/api/v1/loan-stages/update-status/${loan.id}`, formData)
        .then((res) => {
          loan.stages[index] = res.data.loanStage
          this.loanStages = this.loanStages.filter((item) => {
            if (item.id === loan.id) { item.stages = loan.stages }
            return item
          })
        })
        .catch()
    },
    updateExpDate(loan, e) {
      let formData = new FormData();
      formData.append('date', e.target.value)
      this.$http.post(`/api/v1/loans/update-exp-date/${loan.id}`, formData)
        .then(() => { this.pushAlert('success', 'Loan stage updated') })
    },
    updateAppDueDate(loan, e) {
      let formData = new FormData();
      formData.append('date', e.target.value)
      this.$http.post(`/api/v1/loans/update-app-due-date/${loan.id}`, formData)
        .then(() => { this.pushAlert('success', 'Loan stage updated') })
    },
    updateSigningDate(loan, event) {
      let formData = new FormData();
      formData.append("date", event.target.value);
      this.$http
        .post(`/api/v1/loans/update-signing-date/${loan.id}`, formData)
        .then(() => { this.loadLoans() })
        .catch();
    },
    openUWModal(loan, stageId, status, index) {
      this.UWStatusData.loan = loan
      this.UWStatusData.stageId = stageId
      this.UWStatusData.status = status
      this.UWStatusData.index = index
      this.modals.updateUWStatus = true
    },
    updateUWStatus(status) {
      this.setStageStatus(this.UWStatusData.loan, this.UWStatusData.stageId, status, this.UWStatusData.index)
      this.modals.updateUWStatus = !this.modals.updateUWStatus
      this.UWStatusData = {}
    },
    setUWStatus(loan, stageId, status, index, e) {
      let date = null;
      if (e) {
        date = e.target.value;
      }
      let formData = new FormData();
      formData.append("stageId", stageId);
      formData.append("status", status);
      formData.append("date", date);

      this.$http
        .post(`/api/v1/loan-stages/update-status/${loan.id}`, formData)
        .then(res => {
          loan.stages[index] = res.data.loanStage;
          this.loanStages = this.loanStages.filter(item => {
            if (item.id === loan.id) {
              item.stages = loan.stages;
            }

            return item;
          });
        })
        .catch();
    },
  },
  filters: {
    strippedContent: function(string) {
      return string.replace(/<\/?[^>]+>/ig, " ");
    }
  }
}
</script>

<style lang="scss" scoped>
.stage-tippy {
  text-align: right;
}
.unw-status {
  padding: 1px 10px;
  border-radius: 5px;
  background-color: #DDDDDD;
  cursor: pointer;
}
.task-list {
  padding-bottom: 30px;
  border-bottom: 1px solid #e7e8e8;

  &__title {
    padding: 25px 25px 5px 25px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    color: #000;
    font-size: 16px;
  }

  &__body {
    padding: 10px 25px;
  }

  &__item {
    margin-top: 10px;
    &:nth-child(n + 2) {
      margin-top: 15px;
    }
    span {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .date-input {
    width: 125px;
    height: 25px;
    padding-left: 5px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    border: 1px solid #D4D4D4;
    border-radius: 8px;
    background-color: #FFFFFF;
    transition: all .3s;
    margin-left: 10px;
  }
}
</style>
