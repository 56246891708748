<template>
  <div>
    <!--  Page Header  -->
    <base-page-header title="Touch Screen" :subtitle="subtitle" />

    <!--  Page Content  -->
    <div class="page-content p-normal">
      <page-spinner v-if="ui.preLoading" />
      <div class="page-content__body" v-else-if="!ui.preLoading && !ui.activeLoanEmpty">
        <div class="row w-100 m-auto">
          <div class="col-md-3 border-right-light p-0 ">
            <div class="search-wrapper m-0 w-100">
              <img src="@/assets/icons/icon-search.svg" alt="Icon search" />
              <input type="text" placeholder="Search" v-model="search.query" aria-label="search" @keyup="handleSearch"/>
            </div>
          </div>
          <div class="col-md-9 border-bottom-light">
            <div class="d-flex dropdowns">
              <div class="d-flex align-items-center dropdown">
                <label for="fieldLender">Lender:</label>
                <multiselect
                  v-model="lender"
                  placeholder="Select Lender"
                  id="fieldLender"
                  track-by="id"
                  label="name"
                  class="full-width "
                  :options="lenders"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                  @select="handleLender">
                </multiselect>
              </div>
              <div class="d-flex align-items-center dropdown">
                <label for="fieldAgent">Agent:</label>
                <multiselect
                  v-model="agent"
                  placeholder="Select Agent"
                  id="fieldAgent"
                  track-by="id"
                  label="fullName"
                  class="full-width "
                  :options="agents"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="false"
                  :show-labels="false"
                  @select="handleAgent">
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row w-100 m-auto">
          <div class="col-md-3 border-right-light p-0 vh-100">
            <touch-screen-loans-list @select-loan="selectLoan" :loans="loans" :active-loan="activeLoan"/>
          </div>
          <div class="col-md-5 border-right-light p-0 vh-100">
            <page-spinner v-if="ui.loadingContent" />
            <touch-screen-notes
              :loan="activeLoan"
              :notes="activeLoanNotes"
              :tasks="activeLoanTasks"
              :attachments="activeLoanAttachments"
              @loanTouched="handleTouchedLoan"
              @updateNote="updateNotes"
              @taskCreated="getDetails"
              v-else/>
          </div>
          <div class="col-md-4 p-0">
            <page-spinner v-if="ui.loadingContent" />
            <div v-else>
              <div class="page-tabs note-tabs d-flex justify-content-between align-items-center">
                <ul class="tab-list p-0">
                  <li :class="{ active: activeTab === 'stages' }" @click="activeTab = 'stages'">
                    Stages
                  </li>
                  <li :class="{ active: activeTab === 'fileDetails' }" @click="activeTab = 'fileDetails'">
                    File Detail
                  </li>
                </ul>
              </div>
              <touch-screen-file-details-table v-if="activeTab === 'fileDetails'" :loan="activeLoan"/>
              <touch-screen-task-list v-if="activeTab === 'stages'" :loan="activeLoan" :stages="activeLoanStages"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-else>
        <status-message class="status-msg status-msg_alert mt-4 w-50 m-auto" msg="Please add loans" />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import PageSpinner from "../../components/pageSpinner.vue";
import TouchScreenFileDetailsTable from "./includes/TouchScreenFileDetailsTable";
import TouchScreenTaskList from "./includes/TouchScreenTaskList";
import TouchScreenNotes from "./includes/TouchScreenNotes";
import BasePageHeader from "../../components/BasePageHeader.vue";
import TouchScreenLoansList from "./includes/TouchScreenLoansList.vue";
import StatusMessage from "../../components/StatusMessage";

export default {
  name: "TouchScreen",
  components: {
    StatusMessage,
    TouchScreenNotes,
    TouchScreenTaskList,
    TouchScreenFileDetailsTable,
    Multiselect,
    PageSpinner,
    BasePageHeader,
    TouchScreenLoansList,
  },
  data() {
    return {
      loans: [],
      activeLoan: {},
      activeLoanNotes: [],
      activeLoanTasks: [],
      activeLoanAttachments: [],
      activeLoanStages: [],
      lenders: [],
      lender: null,
      agents: [],
      agent: null,
      search: {
        timer: null,
        query: "",
      },
      ui: {
        preLoading: false,
        resetting: false,
        loadingContent: false,
        activeLoanEmpty: false
      },
      activeTab: "stages",
      noteMsg: "",
    };
  },
  methods: {
    getLoans() {
      this.ui.preLoading = true;
      this.ui.loadingContent = true;
      this.$http
        .get("/api/v1/touch-screen", {
          params: {
            query: this.search.query,
            lender: this.lender ? this.lender.id : null,
            agent: this.agent ? this.agent.id : null,
          },
        })
        .then((response) => {
          this.ui.preLoading = false;
          this.loans = response.data.loans;
          this.activeLoan = response.data.activeLoan;
          this.activeLoanStages = response.data.stages;
          this.activeLoan ? this.getDetails(this.activeLoan.id) : this.ui.activeLoanEmpty = true

          this.lenders = Object.values(response.data.lenders);
          this.lenders.unshift({ id: null, name: "All lenders" });
          if (response.data.selectedLender) {
            this.lenders.find((lender) => {
              if (lender === response.data.selectedLender) this.lender = lender;
            });
          }

          this.agents = Object.values(response.data.agents);
          this.agents.unshift({ id: null, fullName: "All agents" });
          if (response.data.selectedAgent) {
            this.agents.find((agent) => { if (agent.id === response.data.selectedAgent.id) this.agent = agent; });
          }
        })
        .catch();
    },
    updateNotes(val) {
      this.activeLoanNotes.unshift(val.data);
    },
    selectLoan(loan) {
      this.activeLoan = loan;
      this.getDetails(loan.id);
    },
    getDetails(loanId) {
      const activeLoanId = loanId === undefined ? this.activeLoan.id : loanId;
      this.ui.loadingContent = true;
      this.$http
        .get(`/api/v1/touch-screen/details/${activeLoanId}`)
        .then((response) => {
          this.activeLoanNotes = response.data.notes;
          this.activeLoanTasks = response.data.notes.filter((el) => {
            if (el.type === "task") return el;
          });
          this.activeLoanAttachments = response.data.attachments;
          this.ui.loadingContent = false;
        });
    },
    handleTouchedLoan(loan) {
      let loans = this.loans
      let index = loans.indexOf(loan)
      loans = loans.map((item) => { return item.id === loan.id ? loan : item })
      loans.push(loans.splice(loans.indexOf(loan), 1)[0])
      this.loans = loans
      this.selectLoan(loans[index > 0 ? index : 0 ])
      this.$root.$emit('updateUser')
    },
    handleSearch() {
      if (this.search.timer) {
        clearTimeout(this.search.timer);
      }

      this.search.timer = setTimeout(() => {
        this.getLoans();
      }, 800);
    },
    handleLender(lender) {
      this.lender = lender;
      this.getLoans();
    },
    handleAgent(agent) {
      this.agent = agent;
      this.getLoans();
    },
    resetDailyTouches() {
      this.ui.resetting = true;
      if (confirm("Reset all touched files?")) {
        this.$http.get("/api/v1/touch-screen/reset-daily-touches").then(() => {
          this.ui.resetting = false;
          this.getLoans();
        });
      } else {
        this.ui.resetting = false;
      }
    },
  },
  computed: {
    subtitle() {
      return `${this.getCounters().loansTouched} Files left to touch.`;
    },
  },
  mounted() {
    this.$root.$on('load-notes-tasks', () => {
      this.getLoans();
    })
  },
  beforeMount() {
    this.getLoans();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  @media (max-width: 1024px) {
    max-height: initial;
  }

  &::v-deep {
    .page-header__subtitle {
      display: none;

      @media (min-width: 1024px) {
        display: inline-block;
      }
    }
  }

  button {
    @media (max-width: 1024px) {
      font-size: 10px;
    }
  }

  .search-wrapper {
    max-width: 100%;
  }
}

.page-content {
  padding: 0;
}

.note-tabs {
  @media (max-width: 1024px) {
    padding: 0 5px;

    li {
      font-size: 11px;
    }
  }
}

.search-wrapper {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  padding: 0 12px;
  background: none;
  border-radius: 0;
  border-bottom: 1px solid #CCCCCC;
  img {
    top: 50%;
    transform: translate(0, -50%);
    left: 12px;
  }
  input {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    height: 100%;
    border: none!important;
    border-radius: 0!important;
    color: rgba(0,0,0, .7);
    font-size: 13px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 18px 0 18px 25px;

    &:active,
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }
}
.border-right-light {
  border-right: 1px solid #CCCCCC!important;
}
.dropdown {
  margin-left: 12px;
  label {
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 4px;
  }
  &::v-deep {
    .multiselect {
      height: 32px;
      width: 170px;
      max-width: 170px;
      min-height: 32px;
      max-height: 32px;
      @media screen and (max-width: 568px) {
        max-width: 50%;
        min-width: 100%;
      }

      &__placeholder {
        padding-top: 0;
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin: 0;
      }

      &__tags {
        min-height: 32px;
        max-height: 32px;
        padding: 7px 40px 0 10px;
        border-radius: 16px;
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        overflow: hidden;
      }

      &__select {
        min-height: 32px;
        max-height: 32px;

        &:before {
          top: 5%;
        }
      }

      &__single {
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        padding: 0;
        margin: 0;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }

      &__input {
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        padding: 0;
      }

      &__option {
        min-height: 32px;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
.dropdowns {
  justify-content: end;
  align-items: center;
  height: 100%;
}

</style>
