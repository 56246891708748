<template>
  <div class="note">
    <div class="d-flex align-items-center justify-content-between note-header">
      <div class="note-subject">{{ loan.borrowerName }}</div>
      <button class="btn btn-cancel btn-header-cancel" @click="touchWithNoNote(loan.id)" v-if="!isTab('Notes')">
        <span v-if="ui.touching" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
        <span v-else>Touch</span>
      </button>
    </div>

    <!--   Note Tabs   -->
    <div class="page-tabs note-tabs d-flex justify-content-between align-items-center">
      <ul class="tab-list p-0">
        <li :class="{ active: isTab('Notes') }" @click="setTab('Notes')">
          Notes <span>{{ notes.length }}</span>
        </li>
        <li :class="{ active: isTab('Todo') }" @click="setTab('Todo')">
          Todo <span>{{ taskTodoCount }}</span>
        </li>
        <li :class="{ active: isTab('Attachments') }" @click="setTab('Attachments')">
          Attachments <span>{{ attachments.length }}</span>
        </li>
      </ul>
      <button class="btn btn-link" @click="ui.showAddTask = true">
        Add Task
      </button>
    </div>

    <!--   Note Textarea   -->
    <div class="note-textarea" v-if="isTab('Notes')">
      <div class="form-group full-width">
        <textarea id="msgField" cols="30" rows="5" class="form-control"
                  placeholder="Write note"
                  v-model="note.msg" @change="$emit('typing', note.msg)"></textarea>
      </div>
      <div class="d-flex align-items-center mt-3 justify-content-between w-100">
        <div class="d-flex">
          <div>
            <base-button title="Add Note" action="secondary-default" @click-btn="submitNote" :loading="ui.submitting"
                         :disabled="ui.submitting" aria-hidden="true"  role="status" size="sm" />
          </div>
          <div>
            <button class="btn btn-cancel w-100" @click="touchWithNoNote(loan.id)">
              <span v-if="ui.touching" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
              <span v-else>Touch without Note</span>
            </button>
          </div>
        </div>
        <button class="btn btn-control" @click="toggleUploader">
          <img src="@/assets/icons/icon-attachment-18.svg" alt="">
        </button>
      </div>
      <file-field-new :files="note.files" field-id="uploader"
                      :multiple="true" class="mt-3" v-if="ui.showUploader" @change="submitStips" />
    </div>

    <div class="note-textarea" v-if="isTab('Notes')">
      <div class="d-flex align-items-end justify-content-between">
        <div class="d-flex align-items-end justify-content-start">
          <div class="dropdown">
            <label for="cannedField" class="mb-1">Send Update Email:</label>
            <multiselect
              v-model="cannedDoc"
              placeholder="Select Email"
              id="cannedField"
              track-by="value"
              label="label"
              class="full-width cs"
              :options="cannedDocOptions"
              :searchable="true"
              :close-on-select="true"
              :allow-empty="false"
              :show-labels="false"
              @select="handleSelectionChange($event,true)"
            >
            </multiselect>
          </div>
        </div>
        <div>
          <base-button title="Preview/Send" action="secondary-default"
                       @click-btn="openPreviewModal" :loading="ui.submitting"
                       aria-hidden="true"  role="status" size="sm"/>
        </div>
      </div>
    </div>

    <!--   Notes List   -->
    <div class="notes-list" id="scrollable-el" v-if="isTab('Todo')">
      <div class="note-list note-click mt-3" v-for="(task, index) in tasks" :key="index">
        <div class="d-flex">
          <img :src="agentPhoto(task.task.assignedBy.photo)" class="avatar" alt="Avatar"/>
          <div class="w-100 note-body ">
            <div class="task-header d-flex justify-content-between">
              <span class="task-header-assigner">{{ task.task.assignedBy.fullName }}</span>
              <span class="task-date">{{ task.task.createdAtForHumans }}</span>
            </div>
            <div class="cursor-pointer">
              <span class="assigned-task">Assigned task</span>
              <div class="task-body">
                <div class="d-flex">
                  <div class="form-group form-check-round me-2" @click="toggleTaskModal(task.task.id, 'subTask')">
                    <input type="checkbox" class="form-check-input" aria-label="status"
                           :checked="task.task.status === 'done'" disabled/>
                  </div>
                  <div class="task-info">
                    <div class="task-info-name" @click="toggleTaskModal(task.task.id, 'subTask')">
                      {{ task.task.title }}
                    </div>
                    <div class="d-flex mt-1">
                      <div class="task-comments" @click="toggleTaskModal(task.task.id, 'Comments')">
                        <img src="@/assets/icons/icon-comment-18.svg" alt="Comments"/>
                        <span>{{ task.task.comments.length }}</span>
                      </div>
                      <div class="task-comments ms-3" @click="toggleTaskModal(task.task.id, 'Attachments')">
                        <img src="@/assets/icons/icon-attachment-18.svg" alt="Files"/>
                        <span>{{ task.task.commentsTotal }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <status-message class="status-msg status-msg_alert mt-3" msg="There's no tasks just yet." v-if="tasks.length === 0"/>
    </div>
    <div class="notes-list" id="scrollable-el" v-if="isTab('Notes')">
      <div class="note-list note-click mt-3" v-for="(note, index) in notes" :key="index">
        <div class="d-flex">
          <img :src="agentPhoto(note.agent.photo)" class="avatar" alt="Avatar" v-if="note.agent"/>
          <div class="w-100 note-body ">
            <div class="task-header d-flex justify-content-between" v-if="note.agent">
              <span class="task-header-assigner" >{{ note.agent.fullName }}</span>
              <span class="task-date">{{ note.createdAtForHumans }}</span>
            </div>
            <uwm-notes v-if="note.type === 'uwm_sync'" :note="note" />
            <div v-else-if="note.type === 'edit'">
              <span class="assigned-task" :class="{edit: note.type === 'edit'}">Application has been updated:</span>
              <div class="app-updated d-flex align-items-center justify-content-between border p-2 "
                   v-for="(log, index) in note.changes" :key="index">
                <div>{{ log.property }}</div>
                <div>
                  <img src="@/assets/icons/icon-value-removed.svg" alt=""/>
                  <span>{{ log.oldValue }}</span>
                </div>
                <div>
                  <img src="@/assets/icons/icon-value-removed-icon-value-added.svg" alt=""/>
                  <span>{{ log.newValue }}</span>
                </div>
              </div>
            </div>
            <div v-else-if="note.type === 'task' || note.status" class="cursor-pointer"
                 :class="{task: note.type === 'task' || note.status}">
              <span class="assigned-task">Assigned task</span>
              <div class="task-body">
                <div class="d-flex">
                  <div class="form-group form-check-round me-2" @click="toggleTaskModal(note.task.id, 'subTask')">
                    <input type="checkbox" class="form-check-input" aria-label="status"
                           :checked="note.task.status === 'done'" disabled/>
                  </div>
                  <div class="task-info">
                    <div class="task-info-name" @click="toggleTaskModal(note.task.id, 'subTask')">
                      {{ note.task.title }}
                    </div>
                    <div class="d-flex mt-1">
                      <div class="task-comments" @click="toggleTaskModal(note.task.id, 'Comments')">
                        <img src="@/assets/icons/icon-comment-18.svg" alt="Comments"/>
                        <span>{{ note.task.comments.length }}</span>
                      </div>
                      <div class="task-comments ms-3" @click="toggleTaskModal(note.task.id, 'Attachments')">
                        <img src="@/assets/icons/icon-attachment-18.svg" alt="Files"/>
                        <span>{{ note.task.commentsTotal }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="note.type === 'note'">
              <span class="assigned-task" :class="{note: note.type === 'note'}">{{ note.message }}</span>
              <div class="attach-file mt-2" v-for="(file, index) in note.files" :key="index">
                <div class="d-flex align-items-center">
                  <img alt="Attach icon" src="@/assets/icons/icon-attachment-18.svg"/>
                  <span class="file-name">{{ file.clientFilename }}</span>
                </div>
                <button @click="downloadDocument(file.id, file.clientFilename, file.type)">
                  <img alt="Delete" src="@/assets/icons/icon-download.svg"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <status-message class="status-msg status-msg_alert mt-3" msg="There's no notes just yet." v-if="notes.length === 0"/>
    </div>
    <div class="notes-list" id="scrollable-el"  v-if="isTab('Attachments')">
      <div class="note-list note-click mt-3" v-for="(note, index) in attachments" :key="index">
        <div class="d-flex">
          <img :src="agentPhoto(note.comment.agent.photo)" class="avatar" alt="Avatar"/>
          <div class="w-100 note-body ">
            <div class="task-header d-flex justify-content-between">
              <span class="task-header-assigner">{{ note.comment.agent.fullName }}</span>
              <span class="task-date">{{ note.createdAtForHumans }}</span>
            </div>
            <div>
              <span class="assigned-task">{{ note.message }}</span>
            </div>
            <div class="attach-file">
              <div class="d-flex align-items-center">
                <img alt="Attach icon" src="@/assets/icons/icon-attachment-18.svg"/>
                <span class="file-name">{{ note.clientFilename }}</span>
              </div>
              <button @click="downloadDocument(note.id, note.clientFilename, note.type)">
                <img alt="Delete" src="@/assets/icons/icon-download.svg"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <status-message class="status-msg status-msg_alert mt-3" msg="There's no attachments just yet."
                      v-if="attachments.length === 0"/>
    </div>
    <div class="d-flex align-items-center add-task" @click="ui.showAddTask = true" v-if="isTab('Todo')">
      <img src="@/assets/icons/icon-add-blue.svg" alt="">
      <img src="@/assets/icons/icon-add-blue.svg" alt="">
      <span>Add Task</span>
    </div>

    <preview-canned-email-modal v-if="ui.previewModal"
                                :loan-id="loan.id"
                                :cannedDoc="cannedDoc ? cannedDoc : null"
                                :cannedDocNoTouch="cannedDocNoTouch ? cannedDocNoTouch : null"
                                @touch="touchLoan"
                                @close="ui.previewModal = false" />

    <transition name="component-fade" mode="out-in">
      <create-task
        v-if="ui.showAddTask"
        @close="ui.showAddTask = false"
        :selectedLoan="loan"
        @load-tasks="$emit('taskCreated')"/>
    </transition>
  </div>
</template>

<script>
import StatusMessage from '../../../components/StatusMessage.vue';
import CreateTask from "../../tasks/modals/CreateTask.vue";
import PerfectScrollbar from 'perfect-scrollbar';
import FileFieldNew from "../../../components/FileFieldNew";
import UwmNotes from '../../loans/UwmNotes';
import BaseButton from '../../../components/BaseButton.vue';
import Multiselect from "vue-multiselect";
import PreviewCannedEmailModal from "@/views/touchScreen/includes/PreviewCannedEmailModal.vue";

export default {
  components: {FileFieldNew, Multiselect, CreateTask, StatusMessage, UwmNotes, BaseButton, PreviewCannedEmailModal},
  name: "TouchScreenNotes",
  props: {
    loan: {type: Object, required: true},
    notes: {type: Array, required: true, default: () => []},
    tasks: {type: Array, required: true, default: () => []},
    attachments: {type: Array, required: true, default: () => []},
  },
  data() {
    return {
      note: {
        tab: "Notes",
        msg: "",
        files: []
      },
      ui: {
        showAddTask: false,
        submitting: false,
        touching: false,
        showTask: false,
        ps: null,
        showUploader: false,
        previewModal: false,
      },
      taskId: null,
      formValue: new FormData(),
      cannedDoc: null,
      cannedDocNoTouch: null,
      touchLoan: false,
      cannedDocOptions: [
        {value: 'loan_appraisal_ordered', label: 'Appraisal Ordered'},
        {value: 'loan_appraisal_received', label: 'Appraisal Received'},
        {value: 'loan_submitted_to_underwriting', label: 'Submitted to Underwriting'},
        {value: 'loan_conditionally_approved', label: 'Conditionally Approved'},
        {value: 'loan_cleared_to_close', label: 'Cleared to Close'},
        {value: 'loan_funded', label: 'Funded'},
        {value: 'loan_no_new_news_update', label: 'No New News Update'},
      ],
    };
  },
  methods: {
    setTab(val) {
      this.ui.ps.destroy();
      this.ui.ps = null;
      this.note.tab = val;
      this.$nextTick(() => {
        const scrollableEl = document.getElementById('scrollable-el');
        this.ui.ps = new PerfectScrollbar(scrollableEl);
      })
    },
    submitStips(files) {
      this.note.files = [];
      let length = files.length
      if (this.formValue.has('files[]')) {
        this.formValue.remove('files[]');
      }
      for(let i = 0; i < length; i++) {
        this.note.files.push(files[i])
        this.formValue.append('files[]', files[i])
      }
    },
    isTab(val) {
      return this.note.tab === val;
    },
    toggleUploader() {
      this.ui.showUploader = !this.ui.showUploader
    },
    touchWithNoNote(loanId) {
      this.ui.touching = true
      this.$http
        .get(`/api/v1/touch-screen/touch-loan/${loanId}`)
        .then((response) => {
          this.ui.touching = false;
          this.$emit("loanTouched", response.data.loan);
        })
        .catch();
    },
    downloadDocument(id, filename, type) {
      console.log(type)
      let url = type === 'note' ? `/api/v1/touch-screen/download/${id}` : `/api/v1/task/comments/download/${id}`
      this.$http
        .get(url, {responseType: 'blob'})
        .then((res) => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data], {type: res.headers['content-type']}));
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch();
    },
    showTask(id) {
      this.taskId = id;
      this.ui.showTask = true;
    },
    submitNote() {
      if (this.note.msg) {
        this.ui.submitting = true;
        this.formValue.append('message', this.note.msg)
        this.$http
          .post(`/api/v1/loans/notes/new/${this.loan.id}`, this.formValue)
          .then((res) => {
            this.$emit("updateNote", res);
            let loan = this.loan
            loan.isDailyTouched = true
            loan.lastTouchAt = '1 second ago'
            this.$emit("loanTouched", loan);
            this.note.msg = "";
            this.ui.submitting = false;
          })
          .catch();
      }
    },
    handleSelectionChange(val, touch) {
      if (touch) {
        this.cannedDoc = val
        this.cannedDocNoTouch = null
      } else {
        this.cannedDocNoTouch = val
        this.cannedDoc = null
      }
      this.touchLoan = touch
    },
    openPreviewModal() {
      if (!this.cannedDoc && !this.cannedDocNoTouch) return;
      this.ui.previewModal = true
    }
  },
  computed: {
    taskTodoCount() {
      return this.tasks.filter(task => {
        return task.task.status !== 'done'
      }).length;
    },
  },
  updated() {
    this.$nextTick(() => {
      this.ui.ps.update()
    })
  },
  mounted() {
    this.$nextTick(() => {
      const scrollableEl = document.getElementById('scrollable-el')
      this.ui.ps = new PerfectScrollbar(scrollableEl)
    })
  },
  beforeDestroy() {
    this.$nextTick(() => {
      this.ui.ps.destroy();
      this.ui.ps = null;
    })
  },
};
</script>

<style lang="scss" scoped>
.page-tabs {
  border-bottom: 1px solid #CCCCCC;
  li {
    padding: 8px 18px 20px 18px;

    span {
      display: flex;
      align-items: center;
      padding: 1px 5.55px;
      border-radius: 8px;
      background-color: #E6E6E6;
      color: rgba(0, 0, 0, .7);
      font-size: 10px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      margin-left: 4px;
    }
  }
}

.note {
  height: 100vh;
  overflow: hidden;

  .notes-list {
    position: relative;
    padding: 0 26px;
    max-height: 45%;
  }

  &-subject {
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    color: #000;
    font-size: 18px;

    @media (max-width: 1024px) {
      padding: 18px 24px 17px 24px;
    }
  }

  &-tabs {
    padding: 0 25px;

    @media (max-width: 1024px) {
      padding: 0 5px;
      li {
        font-size: 10px;
      }
      .btn {
        font-size: 10px;
      }
    }
  }

  &-textarea {
    position: relative;
    padding: 20px 26px 17.5px 26px;
    margin-bottom: 11.5px;

    &:after {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 50%);
      content: '';
      width: calc(100% - 52px);
      height: 1px;
      background: rgba(0, 0, 0, 0.2);
    }

    textarea {
      border: 1px solid #CCCCCC;
      border-radius: 4px;
      background-color: #FFFFFF;
    }

    button {
      @media (max-width: 1024px) {
        font-size: 10px;
      }
    }
  }

  .task-body {
    padding: 9px 9px 11.5px 9px;

    .form-group {
      margin-right: 0 !important;
    }
  }

  &-list {
    .task-info-name {
      color: #000000;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 13px;
      margin-bottom: 9px;
    }

    .task-header-assigner {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }

    .task-header-assigner {
      font-weight: 500;
    }

    .task-date {
      opacity: 0.46;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 16px;
    }

    .assigned-task {
      white-space: break-spaces;
      color: #000000;
      font-size: 14px;
      font-style: italic;
      letter-spacing: 0;
      line-height: 21px;
    }

    .task-body {
      border: 1px solid rgba(239, 239, 239, 1);
      border-radius: 4px;
      padding: 9px;
      min-height: 60px;
      margin-top: 9px;
    }

    .task-comments {
      img {
        opacity: 0.46;
      }

      span {
        display: inline-block;
        color: #8A8A8A;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 11px;
        margin-left: 3px;
      }
    }

    .create-task {
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: 20px;
    }

    .attach-file {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.btn-add-note {
  padding: 8px 12px;
  height: 32px;
  width: 90px !important;
  min-width: 90px;
  border-radius: 8px;
  background-color: #065AE4;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
}

.btn-cancel {
  height: 32px;
  width: 141px;
  border: 1px solid #8A8A8A;
  opacity: 1 !important;
  border-radius: 8px;
  margin-left: 8px;

  span {
    opacity: 0.7;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
}

.edit,
.note {
  .assigned-task {
    font-style: normal;
  }
}

.task {
  .assigned-task {
    font-style: italic;
  }
}

.note-body {
  position: relative;
  padding-bottom: 14px;
  padding-left: 0;

  &::after {
    position: absolute;
    top: 100%;
    //left: 50%;
    display: block;
    width: 100%;
    height: 1px;
    content: '';
    //transform: translate(-50%, 50%);
    background: rgba(0, 0, 0, 0.2);
  }
}

.avatar {
  margin: 0 12px 0 0;
  min-width: 36px;
}
.add-task {
  padding-left: 50px;
  margin-top: 18px;
  cursor: pointer;
  span {
    display: block;
    margin-left: 4px;
    color: rgba(0,0,0, .7);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
  }
}
.btn-header-cancel {
  width: 76px;
  height: 32px;
  margin-right: 19px;
}
.note-header {
  padding: 0 0 13px 24px;
  margin-top: 14px;
  min-height: 45px;
}
.btn-link {
  padding: 0;
}

.dropdown {
  margin-left: 12px;
  label {
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-right: 4px;
  }
  &::v-deep {
    .multiselect {
      height: 32px;
      width: auto;
      max-width: 250px;
      min-height: 32px;
      max-height: 32px;
      @media screen and (max-width: 568px) {
        max-width: 50%;
        min-width: 100%;
      }

      &__placeholder {
        padding-top: 0;
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        margin: 0;
      }

      &__tags {
        min-height: 32px;
        max-height: 32px;
        padding: 7px 40px 7px 10px;
        border-radius: 16px;
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        overflow: hidden;
        width: auto;
      }

      &__select {
        min-height: 32px;
        max-height: 32px;

        &:before {
          top: 5%;
        }
      }

      &__single {
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        padding: 0;
        margin: 0;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }

      &__input {
        color: #000000;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        padding: 0;
      }

      &__option {
        min-height: 32px;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
.cs {
  @media (min-width: 768px) {
    width: 200px !important;
  }
}
</style>
