<template>
  <div class="file-details">
    <div class="file-details__body">
      <div class="table">
        <table class="table">
          <tbody>
          <tr>
            <td>Borrower</td>
            <td>{{ loan.borrowerName }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ loan.status }}</td>
          </tr>
          <tr>
            <td>Loan Amount</td>
            <td>{{ convertToCurrency(loan.loanAmount) }}</td>
          </tr>
          <tr>
            <td>Rate</td>
            <td>%{{ loan.rate }}</td>
          </tr>
          <tr>
            <td>Agent</td>
            <td>{{ loan.agent ? loan.agent.fullName : '' }}</td>
          </tr>
          <tr>
            <td>Lender</td>
            <td>{{ loan.lender }}</td>
          </tr>
          <tr>
            <td>Submitted at</td>
            <td>{{ loan.submittedAt }}</td>
          </tr>
          <tr>
            <td>In Process</td>
            <td>{{ loan.inProcess }} days</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TouchScreenFileDetailsTable',
  props: {
    loan: { type: Object, required: true }
  },
}
</script>

<style lang="scss" scoped>
.file-details {

  &__title {
    padding: 25px 25px 5px 25px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    color: #000;
    font-size: 16px;
  }

  &__body {
    padding: 0 25px;

    @media (max-width: 1024px) {
      padding: 0 10px;
    }

    table.table {
      margin-top: 10px;

      tbody {
        tr {
          border-radius: 0;
          td {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 12px!important;
            height: 36px;
            border-bottom: 1px solid rgba(0,0,0,0.2) !important;
            border-radius: 0!important;
            &:first-child {
              width: 40%;
            }
            width: 60%;
            &:first-child {
              color: rgba(0,0,0, .7);
              font-size: 13px;
              letter-spacing: 0;
              line-height: 18px;
              padding-left: 10px;
            }
            &:last-child {
              color: #000000;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 18px;
            }

            @media (max-width: 1024px) {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
